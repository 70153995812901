import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbySeo } from 'gatsby-plugin-next-seo';
import Layout from '../../components/layout';
import Section from '../../components/section';

import usePoliciesData from '../../hooks/use-policies-data';

const pageTitle = 'Legal Information: Our terms and policies';

const PoliciesListing = () => {
  const nodes = usePoliciesData();
  return (
    <>
      <GatsbySeo
        language="en"
        title={pageTitle}
        description="Learn about David Simpson Apps legal policies regarding agreement terms, data security measures and so on."
        openGraph={{
          url: 'https://www.dsapps.dev/policies/',
        }}
      />
      <Layout pageTitle={pageTitle}>
        <Section pageTitle={pageTitle}>
          <section className="body-font animate-underline-anchors">
            <div className="container flex flex-row py-4 mx-auto ">
              <div>
                <p>These are the legal policies of David Simpson Apps.</p>

                <ul>
                  {nodes.map((node) => {
                    const { id, slug, frontmatter } = node;
                    return (
                      <li key={id}>
                        <Link
                          to={`/policies/${slug}`}
                          className="inline-flex items-center mt-3 dark:text-blue-200"
                        >
                          {frontmatter.title}
                        </Link>
                      </li>
                    );
                  })}
                </ul>

                <p>They are updated from time to time.</p>
              </div>
            </div>
          </section>
        </Section>
      </Layout>
    </>
  );
};

export default PoliciesListing;
