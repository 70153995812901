import { graphql, useStaticQuery } from 'gatsby';

const usePoliciesData = () => {
  const data = useStaticQuery(
    graphql`
      query PolicyDataQuery {
        allMdx(
          sort: { fields: frontmatter___title, order: ASC }
          filter: { fileAbsolutePath: { regex: "/src/mdx/policies/" } }
        ) {
          nodes {
            frontmatter {
              title
            }
            id
            slug
          }
        }
      }
    `,
  );
  return data.allMdx.nodes;
};
export default usePoliciesData;
